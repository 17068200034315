import {IRoomsAction, IRoomsState} from '../../services/IRooms';
import types from './types';
import {get} from 'lodash';

const initialState: IRoomsState = {
  list: null,
  timeTable: null,
  room: null,
  videoRoom: null,
  linksRoom: null,
  timeTableDetails: null,
  chatLiveList: null,
  chatLoad: null,
  chatActivityLiveList: null,
  chatActivityLoad: null,
  companies: null,
  profileCompany: null,
  calendarEvents: null,
  slots: null,
  qviz: null,
  qvizUsers: null,
  settings: null,
  photoList: null,
  gridList: null,
  profile: null,
  line: null,
  city: null,
  talent: null,
  result: null,
  leaders: null,
  myPosition: null,
  timetableActivity: null,
  canLeaveReview: localStorage.getItem('can-leave-review') !== '0',
  occasionData: null
  // params: {
  //   id: null,
  //   type: null,
  // },
};

export default function roomsReducer(state: IRoomsState = initialState, action: IRoomsAction): IRoomsState {
  switch (action.type) {
    case types.GET_ROOM_LIST:
      return {...state, list: action.list};

    case types.GET_TIME_TABLE:
      return {...state, timeTable: action.timeTable};

    case types.GET_ROOM:
      return {...state, room: action.room};

    case types.GET_VIDEO_ROOM:
      return {...state, videoRoom: action.videoRoom};

    case types.GET_OCCASION:
      return {...state, occasionData: action.occasionData};

    case types.GET_LINKS_ROOM:
      return {...state, linksRoom: action.linksRoom};

    case types.GET_TIME_TABLE_DETAILS:
      return {...state, timeTableDetails: action.timeTableDetails};

    case types.CLEAR_TABLE_DETAILS:
      return {...state, timeTableDetails: action.timeTableDetails};

    case types.SET_TIMETABLE_ACTIVITY:
      return {...state, timetableActivity: action.timetableActivity};

    case types.GET_CHAT_LIVE_LIST:
      return {...state, chatLiveList: action.chatLiveList};

    case types.CHAT_LOAD:
      return {...state, chatLoad: action.chatLoad};

    case types.GET_CHAT_ACTIVITY_LIVE_LIST:
      return {...state, chatActivityLiveList: action.chatActivityLiveList};

    case types.CHAT_ACTIVITY_LOAD:
      return {...state, chatActivityLoad: action.chatActivityLoad};

    case types.GET_COMPANIES_COMPANY:
      return {...state, companies: action.companies};

    case types.GET_COMPANY_PROFILE:
      return {...state, profileCompany: action.profileCompany};

    case types.GET_CALENDAR:
      return {...state, calendarEvents: action.calendarEvents};

    case types.GET_SLOTS:
      return {...state, slots: action.slots};

    case types.GET_QVIZ:
      return {...state, qviz: action.qviz};

    case types.GET_QVIZ_USERS:
      return {...state, qvizUsers: action.qvizUsers};

    case types.GET_SETTINGS:
      return {...state, settings: action.settings};

    case types.PHOTO:
      return {...state, photoList: action.photoList};

    case types.ADD_PHOTO:
      return {...state, photoList: {data: [action.photoList, ...get(state, 'photoList.data', [])]}};

    case types.GRID:
      return {...state, gridList: action.gridList};

    case types.PROFILE:
      return {...state, profile: action.profile};

    case types.GET_LINE:
      return {...state, line: action.line};

    case types.GET_CITY:
      return {...state, city: action.city};

    case types.GET_TALENT:
      return {...state, talent: action.talent};
    case types.GET_RESULT:
      return {...state, result: action.result};

    case types.LEADERS:
      return {...state, leaders: action.leaders};

    case types.MY_POSITION:
      return {...state, myPosition: action.myPosition};

    case types.LEAVE_REVIEW:
      return {...state, canLeaveReview: action.canLeaveReview};

    default:
      return state;
  }
}
