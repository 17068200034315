export default {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  RESET_PASSWORD: 'RESET_PASSWORD',
  GET_COMPANIES: 'GET_COMPANIES',
  SAVE_IN: 'SAVE_IN',
  SAVE_UP: 'SAVE_UP',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED',
  USER: 'USER',
  RESOLUTION: 'RESOLUTION',
  COMPLETED_PASSWORD: 'COMPLETED_PASSWORD',
};
