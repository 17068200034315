import { IAuthAction, IAuthState } from '../../services/IAuth';
import checkExpires from '../../tools/checkExpires';
import types from './types';

const token = process.env.ACCESS_TOKEN
const saveIn = process.env.SAVE_IN
const user = process.env.USER

const initialState: IAuthState = {
  companies: null,
  token: token ? {data: {"access_token": token}} : checkExpires('access_token'),
  saveIn: saveIn ? JSON.parse(saveIn) :  checkExpires('saveIn'),
  saveUp: checkExpires('saveUp'),
  completed: false,
  completedPassword: false,
  user: user ? JSON.parse(user) : (checkExpires('access_token') ? checkExpires('user') : null),
  errorRequest: '',
  resolution: true,
};

console.log(initialState)

export default function authReducer(state: IAuthState = initialState, action: IAuthAction): IAuthState {
  switch (action.type) {
    case types.GET_COMPANIES:
      return { ...state, companies: action.companies };

    case types.SIGN_UP:
      return { ...state, token: action.token };

    case types.SAVE_IN:
      return { ...state, saveIn: action.saveIn };

    case types.SAVE_UP:
      return { ...state, saveUp: action.saveUp };

    case types.ERROR:
      return { ...state, errorRequest: action.errorRequest };

    case types.COMPLETED:
      return { ...state, completed: action.completed };

    case types.USER:
      return { ...state, user: action.user };

    case types.RESOLUTION:
      return { ...state, resolution: action.resolution };

    case types.COMPLETED_PASSWORD:
      return { ...state, completedPassword: action.completedPassword };

    default:
      return state;
  }
}
