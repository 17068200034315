import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import authReducer from '../containers/auth/reducer';
import generalReducer from '../containers/general/reducer';
import roomsReducer from '../containers/rooms/reducer';

export const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
  general: generalReducer,
  rooms: roomsReducer,
});
