import React, { useEffect, useState } from 'react';
import { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import logoEn from '../../assets/images/general/logo-en.png';
import logoRu from '../../assets/images/general/logo.png';
import logoTo from '../../assets/images/shared/citrin.png';
import { IReduxStore } from '../../services/IGeneral';

interface IPreloader {
  localName: string;
}

const Preloader: FC<IPreloader> = ({ localName }): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logo, setLogo] = useState<any>();

  const renderLogo = async () => {
    let isLogo;

    switch (localName) {
      case 'RU':
        isLogo = logoTo;
        break;
      case 'EN':
        isLogo = logoTo;
        break;
    }
    setLogo(isLogo);
  };

  useEffect(() => {
    renderLogo();
  });

  return <div/>

  return (
    <div className="preloader">
      <div className="preloader__spinner">
        <img src={logo} alt="logo drive" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxStore) => ({
  localName: state.general.localName!,
});

export default connect(mapStateToProps)(Preloader);
