import { ROOM } from '../../router/server';

export default {
  GET_ROOM_LIST: 'GET_ROOM_LIST',
  GET_TIME_TABLE: 'GET_TIME_TABLE',
  GET_OCCASION: 'GET_OCCASION',
  GET_ROOM: 'GET_ROOM',
  SET_TIMETABLE_ACTIVITY: 'SET_TIMETABLE_ACTIVITY',
  GET_VIDEO_ROOM: 'GET_VIDEO_ROOM',
  GET_LINKS_ROOM: 'GET_LINKS_ROOM',
  GET_CHAT_LIVE_LIST: 'GET_CHAT_LIVE_LIST',
  GET_CHAT_ACTIVITY_LIVE_LIST: 'GET_CHAT_ACTIVITY_LIVE_LIST',
  GET_TIME_TABLE_DETAILS: 'GET_TIME_TABLE_DETAILS',
  CHAT_LOAD: 'CHAT_LOAD',
  CHAT_ACTIVITY_LOAD: 'CHAT_ACTIVITY_LOAD',
  GET_COMPANIES_COMPANY: 'GET_COMPANIES_COMPANY',
  GET_COMPANY_PROFILE: 'GET_COMPANY_PROFILE',
  GET_CALENDAR: 'GET_CALENDAR',
  GET_SLOTS: 'GET_SLOTS',
  GET_QVIZ: 'GET_QVIZ',
  GET_QVIZ_USERS: 'GET_QVIZ_USERS',
  GET_SETTINGS: 'GET_SETTINGS',
  PHOTO: 'PHOTO',
  GRID: 'GRID',
  PROFILE: 'PROFILE',
  GET_LINE: 'GET_LINE',
  GET_CITY: 'GET_CITY',
  GET_TALENT: 'GET_TALENT',
  ADD_LIKE_TALENT: 'ADD_LIKE_TALENT',
  ADD_PHOTO: 'ADD_PHOTO',
  SEND_TALENT: 'SEND_TALENT',
  GET_RESULT: 'GET_RESULT',
  LEADERS: 'LEADERS',
  MY_POSITION: 'MY_POSITION',
  LEAVE_REVIEW: 'LEAVE_REVIEW',
  CLEAR_TABLE_DETAILS: 'CLEAR_TABLE_DETAILS',
};
