import { IGeneralAction, IGeneralState } from '../../services/IGeneral';
import checkExpires from '../../tools/checkExpires';
import types from './types';

const initialState: IGeneralState = {
  localName: checkExpires('localName', 'RU'),
  userName: 'Леонид Иванов',
  userAvatar: 'https://i.pinimg.com/originals/6c/68/b3/6c68b3aeca7987516b42776230b594ed.jpg',
};

export default function generalReducer(state: IGeneralState = initialState, action: IGeneralAction): IGeneralState {
  switch (action.type) {
    case types.LOCAL_NAME:
      return { ...state, localName: action.localName };
    default:
      return state;
  }
}
