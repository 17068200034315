import CONFIG from './config';
import history from './historyCreate';

export const $history = history;
export const $window = window;
export const $document = document;
export const $body = document.body;
export const $root = document.getElementById('root');
export const $app = document.querySelector('.app');

export default CONFIG;
