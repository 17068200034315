/* eslint-disable @typescript-eslint/camelcase */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function checkExpires(itemName: string, defaults: any = null): any {
  const data = localStorage.getItem(itemName);

  if (!data) return defaults;

  switch (itemName) {
    case 'access_token':
      return { data: { access_token: data } };
    case 'localName':
      return data;
    default:
      return JSON.parse(data);
  }
}
